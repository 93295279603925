<template>
  <v-carousel
    :height="'100%'"
    :show-arrows="false"
    :interval="3000"
    cycle
    hide-delimiter-background
    delimiter-icon="mdi-minus"
  >
    <template v-if="items.length">
      <v-carousel-item v-for="(item, i) in items" :key="i">
        <v-img :src="item.imageUrl" :aspect-ratio="3 / 2"></v-img>
      </v-carousel-item>
    </template>
    <v-carousel-item v-else>
      <v-responsive :aspect-ratio="3 / 2">
        <v-skeleton-loader type="image" height="100%" dark></v-skeleton-loader>
      </v-responsive>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  props: ["items"],
  data: () => ({}),
  computed: {},
};
</script>

<style lang="scss" scoped>
::v-deep .v-carousel__controls {
  height: 28px;
}

::v-deep .v-skeleton-loader__image {
  height: 100%;
}
</style>
