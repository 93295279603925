<template>
  <IchibanTemplate ref="ichibanTemplate">
    <template v-slot:body>
      <div class="wrapper">
        <div class="banner mb-4">
          <BannerCarousel :items="banner" />
        </div>
        <div ref="scrollTarget"></div>
        <div class="tool">
          <v-row>
            <v-col cols="12" sm="6" class="px-2 py-1">
              <v-select
                v-model="category"
                :items="categoryOptions"
                @change="() => onFilterChange('category', category)"
                label="一番賞分類"
                outlined
                dense
                hide-details
                solo
                dark
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="px-2 py-1">
              <v-text-field
                append-icon="mdi-magnify"
                label="關鍵字"
                v-model.trim="keyword"
                @change="() => onFilterChange('keyword', keyword)"
                @click:append="() => onFilterChange('keyword', keyword)"
                @click:clear="() => onFilterChange('keyword', '')"
                outlined
                dense
                hide-details
                clearable
                solo
                dark
              >
              </v-text-field>
            </v-col>
            <v-col cols="auto" class="ml-auto">
              <div class="switcher">
                <div
                  class="switcher__item switcher__item--left"
                  :style="{ background: mode === 'grid' ? 'cyan' : '#1e1e1e' }"
                >
                  <v-icon
                    :color="mode === 'grid' ? 'black' : 'white'"
                    @click="mode = 'grid'"
                    >mdi-view-grid</v-icon
                  >
                </div>
                <div
                  class="switcher__item switcher__item--right"
                  :style="{ background: mode === 'row' ? 'cyan' : '#1e1e1e' }"
                >
                  <v-icon
                    :color="mode === 'row' ? 'black' : 'white'"
                    @click="mode = 'row'"
                  >
                    mdi-land-rows-horizontal
                  </v-icon>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div
          v-for="category in filterCategoryList"
          class="py-2"
          :key="category._id"
        >
          <h4 class="text-center category-heading">
            <v-icon color="white">mdi-alert-decagram</v-icon>
            <span>{{ category.name }}</span>
          </h4>
          <div
            :class="{
              'item-wrapper': true,
              grid: mode === 'grid',
              row: mode === 'row',
            }"
          >
            <template v-if="mode === 'row'">
              <IchibanRowCard
                :elevation="4"
                v-for="(ichiban, n) of category.ichibans"
                :key="ichiban._id + n"
                @click="selectIchiban(ichiban)"
                :ichiban="ichiban"
                :keepingIchibans="ichibanKeep"
                :like="likedIchibans.includes(ichiban._id)"
                @like.prevent="handleLike($event, ichiban._id)"
                @unlike.prevent="handleLike($event, ichiban._id)"
              />
            </template>
            <template v-else>
              <IchibanGridCard
                :elevation="4"
                v-for="(ichiban, n) of category.ichibans"
                :key="ichiban.key + category.name"
                @click="selectIchiban(ichiban)"
                :ichiban="ichiban"
                :keepingIchibans="ichibanKeep"
                :like="likedIchibans.includes(ichiban._id)"
                @like.prevent="handleLike($event, ichiban._id)"
                @unlike.prevent="handleLike($event, ichiban._id)"
              />
            </template>
          </div>
        </div>
        <template v-if="!isFetchingIchibans">
          <h4 v-if="!category && !ichibans.length" class="text-center">
            目前沒有符合的一番賞...
          </h4>
          <h4
            v-if="category && !ichibanCategory.ichibans?.length"
            class="text-center"
          >
            目前沒有符合的一番賞...
          </h4>
        </template>
        <v-row justify="center">
          <v-col cols="12">
            <v-container class="max-width">
              <v-pagination
                :value="currentPage"
                @input="(page) => onFilterChange('page', page)"
                :length="ichibanPagination.totalPages"
                :total-visible="5"
                color="cyan"
                class="my-4"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </div>
    </template>
  </IchibanTemplate>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import liff from "@line/liff";
import util from "@/mixins/util";
import login from "@/mixins/login";
import { mapState, mapActions } from "vuex";
import IchibanTemplate from "./IchibanTemplate.vue";
import BannerCarousel from "@/components/dashboard/ichiban/BannerCarousel.vue";
import IchibanGridCard from "@/components/client/IchibanGridCard.vue";
import IchibanRowCard from "@/components/client/IchibanRowCard.vue";
import { includesIgnoreCase } from "@/utils/utils";

export default {
  name: "Ichiban",
  mixins: [util, login],
  components: {
    IchibanTemplate,
    BannerCarousel,
    IchibanGridCard,
    IchibanRowCard,
  },
  data: () => ({
    dialog: false,
    keyword: "",
    category: "",
    banner: [],
    mode: "row",
    likedIchibans: [],
    debounceChangeLiked: null,
    ichibans: [],
    ichibanCategorys: [],
    ichibanCategory: {},
    ichibanKeep: [],
    ichibanPagination: {
      page: 0,
      totalPages: null,
    },
    keyword: "",
    currentPage: 1,
    lastFetch: null,
    isFetchingIchibans: true,
  }),
  async created() {
    this.$vloading.show();
    this.updateFiltersFromQuery();

    await Promise.all([this.getBanner(), this.getIchibanCategory()]);
    this.$vloading.hide();

    const payStatus = this.$route.query.status || null;
    if (payStatus) {
      if (payStatus === "success") {
        const a = await this.$swal
          .fire({
            title: "儲值完成!",
            icon: "success",
          })
          .then(() => {
            const { status, ...remainQuery } = this.$route.query;
            this.$router.replace({
              path: this.$route.path,
              query: remainQuery,
            });
          });
      }
      if (payStatus === "failure") {
        this.$swal
          .fire({
            title: "儲值失敗!",
            icon: "error",
          })
          .then(() => {
            const { status, ...remainQuery } = this.$route.query;
            this.$router.replace({
              path: this.$route.path,
              query: remainQuery,
            });
          });
      }
    }
  },
  mounted() {
    this.debounceChangeLiked = _.debounce(this.changeLiked, 1000);
  },
  async activated() {
    // const shouldRefresh = dayjs().diff(this.lastFetch, "minute") >= 10;
    // if (shouldRefresh) {
    //   await this.refreshData();
    // } else {
    await this.getIchibanKeeps();
    // }
  },
  async deactivated() {},
  computed: {
    filterCategoryList() {
      const combinedList = [
        { id: "reserved", name: "保留中", ichibans: this.ichibanKeep },
      ];
      if (this.$route.query.category) {
        combinedList.push({
          id: this.ichibanCategory._id,
          name: this.ichibanCategory.name,
          ichibans: this.ichibanCategory.ichibans,
        });
      } else {
        combinedList.push({
          id: "all",
          name: "全部",
          ichibans: this.ichibans,
        });
      }
      return combinedList.filter((category) => category.ichibans?.length > 0);
    },
    categoryOptions() {
      const categoryOptions = this.ichibanCategorys.map((category) => ({
        text: category.name,
        value: category._id,
      }));
      return [{ text: "全部", value: "" }, ...categoryOptions];
    },
  },
  methods: {
    selectIchiban(ichiban) {
      this.$router.push({
        name: "IchibanPage",
        params: { id: ichiban._id },
      });
    },
    async getIchibanPagination() {
      this.isFetchingIchibans = true;
      this.$vloading.show();
      const { page = 1, keyword = "", category } = this.$route.query;

      if (category) {
        const { data } = await this.axios.get(
          `/ichibanCategory/${category}/pagination`,
          {
            params: {
              isAvailable: true,
              limit: 12,
              page,
              ...(keyword && { name: keyword }),
            },
          }
        );
        const { data: ichibanCategory, ...ichibanPagination } = data;
        this.ichibanCategory = ichibanCategory;
        this.ichibanCategory.ichibans = this.ichibanCategory.ichibans.filter(
          (ichiban) => ichiban.isAvailable && ichiban.isOnline
        );
        this.ichibanPagination = ichibanPagination;
      } else {
        const { data } = await this.axios.get("/ichiban/pagination", {
          params: {
            isOnline: true,
            isAvailable: true,
            limit: 12,
            page,
            ...(keyword && { name: keyword }),
          },
        });
        const { data: ichibans, ...ichibanPagination } = data;
        this.ichibans = ichibans;
        this.ichibanPagination = ichibanPagination;
      }

      this.lastFetch = dayjs();

      await this.$nextTick();

      this.$vloading.hide();
      this.isFetchingIchibans = false;
    },
    async getIchibanCategory() {
      const { data } = await this.axios.get(`/ichibanCategory`, {
        params: { isAvailable: true },
      });
      const categoryList = data
        .filter((category) => category.ichibans?.length > 0)
        .map((category) => {
          return {
            ...category,
            ichibans: category.ichibans.filter(
              (ichiban) => ichiban.isAvailable && ichiban.isOnline
            ),
          };
        });
      this.ichibanCategorys = categoryList;
    },
    async getIchibanKeeps() {
      const { data } = await this.axios.get(`/ichiban/${this.userID}/keep`);
      this.ichibanKeep = data;
    },
    onFilterChange(type, value) {
      let query = { ...this.$route.query };

      if (type === "page") {
        query.page = value;
      } else if (type === "keyword") {
        query.keyword = value;
        query.page = 1; // 每當 keyword 改變時，重置到第一頁
      } else if (type === "category") {
        query.category = value;
        query.page = 1; // 每當 category 改變時，重置到第一頁
      }

      this.$router.push({ query }); // 更新 URL query
    },
    updateFiltersFromQuery() {
      const { page, keyword, category } = this.$route.query;
      this.currentPage = page ? parseInt(page) : 1;
      this.keyword = keyword || "";
      this.category = category || "";
    },
    async getBanner() {
      const { data } = await this.axios.get("/banner");
      this.banner = data.filter((banner) => !!banner.imageUrl);
    },
    getIsSoldout(ichiban) {
      return (
        ichiban.count === ichiban.sold ||
        ichiban.rewards.every((reward) => !!reward.hit)
      );
    },
    async getLikedIchibans() {
      const { data } = await this.axios.get(`/users/favorites/${this.userID}`);
      this.likedIchibans = data.ichibans.map((ichiban) => ichiban._id);
    },
    async changeLiked(payload) {
      await this.axios.post(`/users/favorites`, {
        userID: this.userID,
        ichibans: payload,
      });
      await this.getLikedIchibans();
      this.$forceUpdate();
    },
    async handleLike(_$event, id) {
      // 直接更新 likedIchibans 讓畫面響應更新樣式
      let payload = this.likedIchibans;
      if (payload.includes(id)) {
        const index = payload.indexOf(id);
        payload.splice(index, 1);
      } else {
        payload.push(id);
      }
      this.debounceChangeLiked(payload);
    },
  },
  watch: {
    userID(val) {
      if (val) {
        this.getLikedIchibans();
        this.getIchibanKeeps();
      }
    },
    "$route.query": {
      immediate: true,
      handler(newQuery) {
        this.updateFiltersFromQuery();
        this.getIchibanPagination(true);
      },
    },
  },
  beforeRouteUpdate(to, from, next) {
    const target = this.$refs.scrollTarget;
    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    const container = this.$refs.ichibanTemplate.$refs.scrollBody;
    if (container) {
      sessionStorage.setItem("scrollPositionY", container.scrollTop);
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$nextTick(() => {
        const container = vm.$refs.ichibanTemplate.$refs.scrollBody;
        if (container) {
          const scrollTop = parseInt(
            sessionStorage.getItem("scrollPositionY") || "0",
            10
          );
          container.scroll({
            top: scrollTop,
            behavior: "smooth",
          });
          sessionStorage.removeItem("scrollPositionY");
        }
      });
    });
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  color: white;
}

.banner {
  width: 100%;
  max-width: 500px;
  height: auto;
  margin: 0 auto;
  padding: 20px 16px;

  @media (min-width: 600px) {
    padding: 30px 24px;
  }

  background-image: url(~@/assets/banner-frame.png);
  background-size: 100% 100%;
}

.tool {
  // width: calc(100% - 24px);
  max-width: 500px;
  margin: 0 auto;
  padding: 10px;
}

.switcher {
  display: flex;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;

    &--left {
      border-radius: 5px 0 0 5px;
    }

    &--right {
      border-radius: 0 5px 5px 0;
    }
  }
}

.item-wrapper {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0px;

  &.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;

    @media (min-width: 600px) {
      // grid-template-columns: repeat(auto-fit, minmax(150px, 180px));
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
    }
  }

  &.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.category-heading {
  font-size: 24px;

  > * {
    vertical-align: middle;
  }
}
</style>
